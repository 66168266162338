#description {
	font-size: 15px;
	font-weight: 300;
}

#infowindow-content .title {
	font-weight: bold;
}

#infowindow-content {
	display: none;
}

#map #infowindow-content {
	display: inline;
}

.pac-card {
	margin: 10px 10px 0 0;
	border-radius: 2px 0 0 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	background-color: #fff;
}

#pac-container {
	padding-bottom: 12px;
	margin-right: 12px;
}

.pac-container {
	z-index: 10000 !important;
}

.pac-controls {
	display: inline-block;
	padding: 5px 11px;
}

.pac-controls label {
	font-size: 13px;
	font-weight: 300;
}

#pac-input {
	background-color: #fff;
}

.controls {
	background-color: #fff;
	font-size: 15px;
	font-weight: 300;
	margin-left: 12px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
}

#pac-input:focus {
	border-color: #4d90fe;
}
