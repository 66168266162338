a {
	display: inline-block;
	padding-bottom: 3px;
}
a:after {
	display: block;
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
}
a:hover:after {
	transform: scaleX(1);
}
