.loading::after {
    animation: loading 0.5s infinite linear;
    border: 0.1rem solid #64abfb;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 0.8rem;
    left: 50%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;
    position: absolute;
    top: 50%;
    width: 0.8rem;
    z-index: 1;
  }
  
  .pay-background {
    background-color: transparent;
  }
  
  .wpwl-group-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wpwl-label {
    margin-bottom: 5px;
  }
  
  .wpwl-button-pay {
    background-color: #64abfb !important;
    border-color: #64abfb !important;
    width: 100%;
  }

  .content {
    padding: 10px;
    margin-top: 15px;
  }
  
  /* default value, applies to all devices */
  .button {
    width: 100%;
  }
  
  @media (min-width: 480px) {
  
    /* this rule applies only to devices with a minimum screen width of 480px */
    .button {
      width: 50%;
    }
  }
  
  .wpwl-form {
    max-width: 40em !important;
  }
  
  /* Override the PeachPayment form styling... */
  .wpwl-container {
    max-width: 40em !important;
    width: 100%
  }

  input,
  .button {
    height: 44px;
    width: 100%;
  }
  